<template>
  <div class="page">
    <section class="hero is-banner is-primary">
      <div class="hero-body">
        <div class="container">
          <div class="columns is-mobile">
            <div class="column">
              <h2 class="is-3 title">
                {{ $t('Price Auto Reflection') }}
              </h2>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="section">
      <div class="container">
        <div class="columns is-mobi">
          <div class="column">
            <SearchForm @filter="fetchData" />
          </div>
          <div class="column is-narrow">
            <button
              v-permission="['modify-rms']"
              class="ant-btn ant-btn-lg ant-btn-primary"
              @click="saveSettings"
            >
              {{ $t('Save') }}
            </button>
          </div>
        </div>

        <a-table
          :columns="columns"
          :data-source="properties"
          :loading="isFetching"
          :custom-row="rowClicked"
          :bordered="true"
          :scroll="{ x: scrollWidth }"
          @change="handleTableChange"
        >
          <template #property-name="{ record }">
            {{ record.name }}
          </template>
          <template
            v-for="vendor in ViewMode.GRID === viewMode && vendors || []"
            :key="vendor.code"
            #[vendor.code]="{ record }"
          >
            <a-checkbox v-model:checked="record[vendor.code]" />
          </template>
          <template v-if="ViewMode.LIST === viewMode" #settings="{ record }">
            <div class="is-flex is-justify-content-space-between" style="width: {{scrollWidth}}px">
              <a-checkbox
                v-for="vendor in vendors"
                :key="vendor.code"
                v-model:checked="record[vendor.code]"
                class="mr-5"
              >
                {{ vendor.name }}
              </a-checkbox>
            </div>
          </template>
        </a-table>
      </div>
    </div>
  </div>
</template>

<i18n>
{
  "en": {
    "Price Auto Reflection": "Price Auto Reflection",
    "Save": "Save",
    "RMS Name": "RMS Name",
    "Currency": "Currency",
    "Status": "Status",
    "active": "active",
    "inactive": "inactive",
  },
  "ja": {
    "Price Auto Reflection": "Price Auto Reflection",
    "Save": "Save",
    "RMS Name": "RMS名称",
    "Currency": "通貨",
    "Status": "ステータス",
    "active": "有効",
    "inactive": "無効",
  }
}
</i18n>

<script>
import SearchForm from './components/SearchForm';
import { getResponseError } from '@/utils/util';

const ViewMode = {
  LIST: 'list',
  GRID: 'grid',
};

export default {
  name: 'PriceAutoReflection',
  components: {
    SearchForm,
  },
  mixins: [],
  data() {
    return {
      properties: undefined,
      oProperties: undefined,
      isFetching: false,
      ViewMode,
      viewMode: ViewMode.LIST,
      vendors: undefined,
      columns: undefined,
      scrollWidth: window.innerWidth,
    };
  },
  watch: {
    async $route(route) {
      await this.fetchData(route.query.name);
    },
  },
  beforeCreate() {
    this.$store.dispatch('iscm-price-auto-reflection/listExternalVendors').then((vendors) => {
      this.vendors = vendors;
      this.scrollWidth = Math.max(200 * vendors.length, this.scrollWidth - 48);
      const settingColumns = {
        title: this.$t('Auto Reflection Settings'),
      };

      if (ViewMode.GRID === this.viewMode) {
        settingColumns.children = vendors.map((vendor) => {
          const column = {
            title: vendor.name,
            dataIndex: vendor.code,
            slots: {
              customRender: vendor.code,
            },
            align: 'center',
            width: 200,
          };

          return column;
        });
      } else {
        settingColumns.slots = { customRender: 'settings' };
      }

      this.columns = [
        {
          title: this.$t('Property'),
          dataIndex: 'property-name',
          slots: { customRender: 'property-name' },
          width: 300,
          fixed: 'left',
        },
        settingColumns,
      ];
    });
  },
  async created() {
    await this.fetchData(this.$route.query?.name);
  },
  updated() {
    const table = document.querySelector('.price-table .ant-table-body');

    if (table) {
      const { scrollWidth, clientWidth } = table;
      const maxScroll = scrollWidth - clientWidth;

      table.scrollLeft = Math.floor(maxScroll / 5);
    }
  },
  methods: {
    async fetchExternalVendor() {
      return null;
    },

    async fetchData(name) {
      this.$store.commit('SHOW_FULLSCREEN_LOADER');
      try {
        if (!this.oProperties?.length) {
          const { properties } = await this.$store.dispatch('iscm-properties/list', {});
          const propertieIds = properties.map((item) => item.hotelNo);
          const settings = await this.$store.dispatch('iscm-price-auto-reflection/listSettings', propertieIds);
          this.oProperties = properties.map((property) => {
            settings[property.hotelNo].forEach((setting) => {
              const { externalVendorId: vendor, status } = setting;
              property[vendor] = status;
            });
            property.key = property.hotelNo;

            return property;
          });
        }

        this.properties = this.oProperties.filter((property) => property.name.match(name));
      } catch (error) {
        this.$message.error(getResponseError(error) || 'Error');
      }

      this.$store.commit('HIDE_FULLSCREEN_LOADER');
    },

    async saveSettings() {
      this.$store.commit('SHOW_FULLSCREEN_LOADER');
      try {
        const priceAutoReflections = this.properties.map((property) => this.vendors.map((item) => {
          const row = {
            propertyId: property.hotelNo,
            externalVendorId: item.code,
            status: property[item.code],
          };

          return row;
        })).flat();
        await this.$store.dispatch('iscm-price-auto-reflection/saveSettings', { priceAutoReflections });
      } catch (error) {
        this.$message.error(getResponseError(error) || 'Error');
      }

      this.$store.commit('HIDE_FULLSCREEN_LOADER');
      return null;
    },
  },
};
</script>
